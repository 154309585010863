.item {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 175px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  text-align: right;
}

.info .title {
  float: right;
  text-align: left;
  width: fit-content;
  color: black;
}

.info .title::before {
  display: inline-block;
  content: '';
  border-bottom: 22px solid white;
  border-left: 31px solid transparent;
  transform: translate(0px, 5px);
}

.info .title h1 {
  display: inline;
  font-size: 14px;
  margin: 0;
  background-color: white;
  padding: 2px 5px;
}

.info .extra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 21px;
  font-size: 12px;
  background-color: white;
  color: black;
}

.info .extra > * {
  padding: 0px 5px;
}

.info .extra .year {
  font-size: 12px;
  margin: 5px 0px;
}
