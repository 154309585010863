.container {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profileImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 5px;
    cursor: pointer;
}

.container .clickMe {
    font-size: 10px;
    font-style: italic;
}