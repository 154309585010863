.App {
    perspective: 2px;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.parallax {
    display: block;
    position: absolute;
    background-image: url('../public/img/parallax.png');
    background-repeat: repeat;
    transform: translateZ(-4px) scale(4);
    width: 200%;
    height: 200%;
    /* background-color: #ffffff;
    opacity: 1;
    background-image: radial-gradient(#ffd5df 0.25px, #ffffff 0.25px);
    background-size: 5px 5px; */
}

.content {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    transform: translateZ(0) scale(1);
    z-index: 10;
}

@media screen and (max-width: 1380px) {
    .content {
        max-width: 1000px;
    }
}