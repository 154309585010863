.container {
  margin-bottom: 50px;
}

.header, .intro {
  width: fit-content;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 10px 30px;
}

.header { margin-top: 30px; margin-bottom: 30px; }
.header h1 { margin: 0; }

@keyframes float {
  0% { transform: translateY(-3px); }
  50% { transform: translateY(3px); }
  100% { transform: translateY(-3px); } 
}
.float { animation: float 3s infinite ease-in-out; }

@keyframes wiggle {
  0% { transform: rotate(.5deg); }
  50% { transform: rotate(-.5deg); }
  100% { transform: rotate(.5deg); }
}
.wiggle { animation: wiggle 4s infinite ease-in-out; }

.intro {
  max-width: 800px;
  padding: 30px;
}

.intro h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.intro p {
  font-size: 14px;
  margin: 0;
}
.intro p:not(:nth-child(1)) {
  margin-top: 10px;
}

.salmon { color: #d9a29a; }
.blue { color: #97cbc6; }
.green { color: #a8c191; }
.purple { color: #c1beed; }