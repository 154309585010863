.container { margin-bottom: 50px; }

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  margin-bottom: 40px;
}

@media screen and (max-width: 1380px) {
  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}