body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow {
  box-shadow: 0px 0px 10px -10px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
}

.card {
  border: 1px dashed #ddd;
}

p { font-weight: 200; }

a {
  color: #b03854;
}
