.container {
    display: grid;
    grid-template-columns: .6fr .4fr;
    width: 100%;
    margin-bottom: 20px;
    transition: height cubic-bezier(0.075, 0.82, 0.165, 1) .4s;
    opacity: 0;
    height: 0;
    background-color: white;
}

.container.expanded {
    height: 400px;
    opacity: 1;
}

.media { height: inherit; }

.media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
    box-sizing: border-box;
    padding: 30px 20px;
}

.info pre {
    display: inline;
    padding: 2px 5px;
    background-color: #eaeaea;
}

.info h1 {
    font-size: 20px;
}

.info .description {
    font-size: 14px;
}

.info .techniques {
    width: fit-content;
    padding: 2px 5px;
    font-size: 12px;
    background-color: black;
    color: white;
}