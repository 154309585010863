.spacer {
    display: block;
    height: 20px;
    padding-top: 20px;
}

.container {
    display: grid;
    grid-template-columns: .3fr .7fr;
    margin-bottom: 40px;
    margin-top: 40px;
}

.container > * {
    box-sizing: border-box;
    padding: 30px 5%;
}

.profile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
}
.profile > * { width: 100%; }
.profile span {
    text-align: center;
    font-size: 38px;
}
.profile p { font-size: 14px; }
.profile hr {
    width: 80%;
    border: none;
    border-bottom: 1px dashed #aaa;
    margin-top: 20px;
    margin-bottom: 20px;
}
.profile h1 {
    font-size: 16px;
    margin-top: 0px;
}
.profile ul { list-style: none; padding: 0; margin: 0; }
.profile li { margin-right: 5px; }
.profile li svg { width: 20px; }
.profile li p,
.profile li a {
    font-size: 14px;
    margin: 2px 0px 2px 15px;
    display: inline;
}
.profile li a:visited {
    color: black;
}

.resume {
    background-color: white;
}

.resumeList { margin-bottom: 40px; }
.resumeList ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 20px;
}
.resumeList li { margin-bottom: 20px; }
.resumeList li > * { margin: 0; }
.resumeList p { font-size: 14px; }

@keyframes colorRotate {
  from { color: #6666ff; }
  10% { color: #0099ff; }
  75% { color: #bd4361; }
  100% { color: #6666ff; }
}
.profile .hat {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
  animation: colorRotate 6s linear 0s infinite;
}

.subliminalMessage {
    position: absolute;
    text-align: center;
    width: 210px;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 0);
}

.hiremecat {
    width: 150px;
    transform: translate(-20px, 0);
}

.resumeList section ul {
    margin: 0;
}

.resumeList section li {
    display: inline-block;
    box-sizing: border-box;
    padding: 5px 15px;
    border: 1px dashed #aaa;
    margin: 0;
}

.resumeList table {
    font-size: 14px;
    margin-bottom: 30px;
}

.resumeList table td,
.resumeList table th {
    box-sizing: border-box;
}

.resumeList table th,
.resumeList table td {
    text-align: left;
}

.resumeList table th:nth-child(1),
.resumeList table td:nth-child(1) {
    width: 280px;
}

.resumeList table th:nth-child(2),
.resumeList table td:nth-child(2) {
    width: 100px;
}

.resumeList .columns > *:nth-child(2) {
    padding-left: 20px;
    border-left: 1px dashed #aaa;
}

.resumeList .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.resumeList .columns > * {
    margin-left: 10px;
}

@media screen and (max-width: 1380px) {
    .container {
        grid-template-columns: .4fr .6fr;
    }

    .resumeList .columns {
        display: initial;
    }

    .resumeList .columns > *:nth-child(2) {
        border: none;
        padding: 0;
    }
}